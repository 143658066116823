<!--
 * @Author: mulingyuer
 * @Date: 2021-09-22 15:16:10
 * @LastEditTime: 2021-10-21 15:24:19
 * @LastEditors: mulingyuer
 * @Description: 信息发布
 * @FilePath: \saas-admin-vue\src\modules\gov\components\bid-data\InformationRelease.vue
 * 怎么可能会有bug！！！
-->
<template>
  <BDCard title="信息发布" :loading="loading">
    <template #headRight>
      <el-select v-model="form.tab" size="mini" style="width:100px" @change="getInfoPost">
        <el-option v-for="op in tabArr" :key="op.id" :label="op.label" :value="op.id">
        </el-option>
      </el-select>
      <AssociationSelect class="ml-2" v-model="form.project_id" @change="getInfoPost" />
    </template>
    <div class="information-release">
      <div class="left">
        <div class="item">
          <h2 class="title">累计发布{{tabName|placeholder}}数</h2>
          <p class="subtitle">
            <strong>{{infoData.total_count|toPrice|placeholder}}</strong>
            <span class="name">周同比</span>
            <template v-if="totalStatus===0">
              {{false|placeholder}}
            </template>
            <template v-else>
              <i :class="{
            'el-icon-caret-top':totalStatus === 1,
            'success':totalStatus === 1,
            'el-icon-caret-bottom':totalStatus === 2,
            'error':totalStatus === 2}"></i>
              <span v-if="totalStatus" class="content">{{infoData.week_ratio|placeholder}}%</span>
            </template>
          </p>
        </div>
        <div class="item">
          <h2 class="title">最近7天新增{{tabName|placeholder}}数</h2>
          <p class="subtitle">
            <strong>{{infoData.week_count|toPrice|placeholder}}</strong>
            <span class="name">周环比</span>
            <template v-if="weekStatus===0">
              {{false|placeholder}}
            </template>
            <template v-else>
              <i :class="{
            'el-icon-caret-top':weekStatus === 1,
            'success':weekStatus === 1,
            'el-icon-caret-bottom':weekStatus === 2,
            'error':weekStatus === 2}"></i>
              <span v-if="weekStatus" class="content">{{infoData.week_ring_ratio|placeholder}}%</span>
            </template>
          </p>
        </div>
      </div>
      <div class="right">
        <div class="head">
          <MyDatePicker v-model="form.time" ref="MyDatePicker" @change="getInfoPost" />
        </div>
        <div class="content" ref="chartContent">
        </div>
      </div>
    </div>
  </BDCard>
</template>

<script>
import BDCard from "../../layout/big-data/BDCard.vue";
import AssociationSelect from "./AssociationSelect.vue";
import MyDatePicker from "./MyDatePicker.vue";
import MyLineChart from "../../assets/echarts/line-chart";
import { infoPost } from "../../api/big-data/index";
export default {
  data() {
    return {
      loading: false, //加载中
      form: {
        project_id: -1, //协会id
        time: [-1, -1],
        // start_time: -1, //开始时间
        // end_time: -1, //结束时间
        tab: "article",
      },
      tabArr: [
        { label: "资讯发布", id: "article" },
        { label: "活动发布", id: "activity" },
        { label: "资源发布", id: "circle_content" },
      ],
      infoData: {}, //获取的数据
      //图表数据
      chartData: [],
      //x轴坐标数据
      xData: [],
    };
  },
  computed: {
    //选中的tabname
    tabName({ form, tabArr }) {
      const { tab } = form;
      let name = "";
      switch (tab) {
        case "article":
          name = "资讯";
          break;
        case "activity":
          name = "活动";
          break;
        case "circle_content":
          name = "资源";
          break;
      }
      return name;
    },
    //周同比状态
    totalStatus({ infoData }) {
      return infoData.total_status ?? 0;
    },
    //周环比状态
    weekStatus({ infoData }) {
      return infoData.week_status ?? 0;
    },
  },
  methods: {
    //获取信息发布数据
    getInfoPost() {
      this.loading = true;
      const postData = this.getFormData();
      infoPost(postData)
        .then((res) => {
          const { data } = res;
          this.infoData = data;

          this.transformData();
          this.initChart();
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    //获取提交的form数据
    getFormData() {
      const data = { ...this.form };
      const [start_time = "", end_time = ""] = data.time;
      Object.assign(data, { start_time, end_time });
      Reflect.deleteProperty(data, "time");
      return data;
    },
    //转换数据格式
    transformData() {
      const { list = [] } = this.infoData;
      this.chartData = list.map((item) => item.value);
      this.xData = list.map((item) => item.title);
    },
    //更新数据
    onUpdata() {
      this.loading = true;
      this.getInfoPost()
        .then(() => {
          this.setChartOption();
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    //初始化图表
    initChart() {
      if (!this.$chart) {
        this.$chart = new MyLineChart({
          container: this.$refs.chartContent,
          data: this.chartData,
          xData: this.xData,
        });
      } else {
        this.setChartOption();
      }
    },
    //设置图表
    setChartOption() {
      this.$chart.setOption(this.xData, this.chartData);
    },
    //销毁实例
    destroyChart() {
      if (this.$chart) {
        this.$chart.dispose();
      }
    },
  },
  created() {
    this.$nextTick(() => {
      //获取时间默认值
      this.$refs["MyDatePicker"].updateTime();
      this.getInfoPost();
    });
  },
  beforeDestroy() {
    this.destroyChart();
  },
  components: {
    BDCard,
    MyDatePicker,
    AssociationSelect,
  },
};
</script>

<style lang="scss" scoped>
.information-release {
  height: 100%;
  display: flex;
  .left,
  .right {
    height: 100%;
  }
  .left {
    min-width: 240px;
    flex-shrink: 0;
    padding-top: 20px;
    .item {
      font-size: 14px;
      line-height: 22px;
      color: #888;
      & + .item {
        margin-top: 50px;
      }
      .title {
        margin-bottom: 4px;
        font-size: inherit;
        font-weight: normal;
        @include nowrap();
      }
      .subtitle {
        strong {
          font-size: 25px;
          line-height: 35px;
          font-weight: normal;
          color: #252631;
          margin-right: 8px;
        }
        i {
          margin-right: 5px;
          margin-left: 5px;
          font-size: 16px;
          &.success {
            color: $success;
          }
          &.error {
            color: $danger;
          }
        }
        .content {
          color: #252631;
        }
      }
    }
  }
  .right {
    min-width: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    .head {
      flex-shrink: 0;
      margin-bottom: 10px;
    }
    .content {
      min-width: 0;
      flex-grow: 1;
    }
  }
}
</style>

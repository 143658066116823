<!--
 * @Author: dongjia
 * @Date: 2021-09-29 09:48:59
 * @LastEditTime: 2021-09-29 11:40:34
 * @LastEditors: aleaner
 * @Description: 政务基本信息
 * @FilePath: \gov\views\base-message\index.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="base-msg" v-loading="loading">
    <BaseInformation v-if="baseForm" :data="baseForm" />
  </div>
</template>

<script>
import { baseMsg } from "../../api/base-message/index";
import BaseInformation from "../../components/base-message/BaseInformation";
export default {
  components: { BaseInformation },
  data() {
    return {
      loading: false,
      baseForm: null,
    };
  },
  created() {
    this.loading = true;
    baseMsg()
      .then((res) => {
        console.log(res.data, "baseMsg");
        this.baseForm = res.data;
        this.loading = false;
      })
      .catch((err) => {
        this.loading = false;
      });
  },
};
</script>

<style lang="scss" scoped>
</style>

<!--
 * @Author: mulingyuer
 * @Date: 2021-09-16 15:12:54
 * @LastEditTime: 2021-10-21 17:57:51
 * @LastEditors: mulingyuer
 * @Description: 商协会大数据
 * @FilePath: \saas-admin-vue\src\modules\gov\views\big-data\index.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="big-data" :class="{full:isFull}">
    <div class="header">商协会大数据</div>
    <div class="content" v-loading="loading">
      <div class="row">
        <div class="left-col">
          <!-- 基础数据 -->
          <BasicData :data="basicData" />
        </div>
        <div class="right-col">
          <!-- 协会排名 -->
          <AssociationRanking />
        </div>
      </div>
      <div class="row">
        <div class="left-col left-chart">
          <div class="left">
            <!-- 会员画像 -->
            <MemberPortrait />
          </div>
          <div class="right">
            <!-- 会员增长趋势 -->
            <MemberGrowth />
          </div>
        </div>
        <div class="right-col">
          <MemberRevenue />
        </div>
      </div>
      <div class="row">
        <div class="left-col chart-height">
          <!-- 信息发布 -->
          <InformationRelease />
        </div>
        <div class="right-col chart-height">
          <!-- 企业种类 -->
          <CompanyType />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AssociationRanking from "../../components/bid-data/AssociationRanking.vue";
import BasicData from "../../components/bid-data/BasicData.vue";
import MemberPortrait from "../../components/bid-data/MemberPortrait.vue";
import CompanyType from "../../components/bid-data/CompanyType.vue";
import MemberGrowth from "../../components/bid-data/MemberGrowth.vue";
import InformationRelease from "../../components/bid-data/InformationRelease.vue";
import MemberRevenue from "../../components/bid-data/MemberRevenue.vue";
import { basic, associationList } from "../../api/big-data/index";
export default {
  data() {
    return {
      loading: false, //加载中
      basicData: {}, //基础数据
      isFull: true, //是否全屏显示
    };
  },
  methods: {
    //获取基础数据
    getBasicData() {
      return new Promise((resolve, reject) => {
        basic()
          .then((res) => {
            const { data } = res;
            this.basicData = data;

            return resolve(true);
          })
          .catch((error) => {
            return reject(error);
          });
      });
    },
    //获取协会列表
    getAssociationList() {
      associationList()
        .then((res) => {
          const { data } = res;
          this.$store.commit("bigData/setAssociationList", data);
        })
        .catch(() => {});
    },
  },
  async created() {
    try {
      this.loading = true;
      await this.getBasicData();
      await this.getAssociationList(); //协会列表
      this.loading = false;
    } catch (error) {
      console.error(error);
      this.loading = false;
    }
  },
  components: {
    CompanyType,
    AssociationRanking,
    BasicData,
    MemberPortrait,
    MemberGrowth,
    InformationRelease,
    MemberRevenue,
  },
};
</script>

<style lang="scss" scoped>
.big-data {
  background-color: $bg-color;
  margin: -$space;
  // border-radius: $radius;
  // overflow: hidden;
  overflow-x: auto;
  &.full {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    margin: 0;
    padding: $space;
    z-index: 99;
    padding: 0;
  }
  .header {
    width: 100%;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #3576ff;
    color: #ffffff;
    font-size: 18px;
    line-height: 18px;
    font-weight: bold;
  }
  .content {
    padding: 20px;
    min-height: calc(100vh - #{$header-h});
    max-width: 2000px;
    min-width: 1200px;
    margin: auto;
    .row {
      display: flex;
      & + .row {
        margin-top: 24px;
      }
      > .left-col {
        flex-grow: 1;
        min-width: 0;
        margin-right: 24px;
      }
      > .right-col {
        flex-shrink: 0;
        width: 378px;
      }
    }
    .left-chart {
      display: flex;
      > .left {
        flex-shrink: 0;
        width: 378px;
        height: 358px;
        margin-right: 24px;
      }
      .right {
        flex-grow: 1;
        min-width: 320px;
        height: 358px;
      }
    }
    .chart-height {
      height: 358px;
    }
  }
}
</style>

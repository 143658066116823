<!--
 * @Author: mulingyuer
 * @Date: 2021-09-07 09:59:14
 * @LastEditTime: 2021-09-07 10:46:52
 * @LastEditors: mulingyuer
 * @Description: 证书查看
 * @FilePath: \super-admin-vue\src\base\components\Preview\CertView.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="cert-view" :style={width,height}>
    <div class="content">
      <div class="img-wrap" :class="{pdf:isPdf}">
        <img v-if="isPdf" :src="require('@/base/assets/images/icon-pdf.png')">
        <img v-else v-miniSrc="src">
      </div>
      <div class="tool" @click="onPreview">
        <i class="el-icon-view"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { getFileType } from "@/base/utils/tool";
export default {
  props: {
    //证书链接
    src: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      default: "340px",
    },
    height: {
      type: String,
      default: "150px",
    },
  },
  data() {
    return {};
  },
  computed: {
    //是否为pdf文件
    isPdf({ src }) {
      return getFileType(src) === "pdf";
    },
  },
  methods: {
    //预览
    onPreview() {
      if (this.isPdf) {
        window.open(this.src, "_blank");
      } else {
        if (this.src.trim() === "") {
          this.$message.info("预览地址为空，无法预览");
        } else {
          this.$previewImage([this.src]);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.cert-view {
  background-color: #f8faff;
  border: 1px dashed #d8dce6;
  cursor: pointer;
  .content {
    height: 100%;
    position: relative;
    .img-wrap {
      height: 100%;
      &.pdf {
        padding: 20px;
      }
      img {
        width: 100%;
        height: 100%;
        vertical-align: top;
        object-fit: contain;
      }
    }
    .tool {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.3);
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      transition: opacity 0.25s;
      i {
        font-size: 30px;
        color: #fff;
      }
    }
  }

  &:hover {
    .content .tool {
      opacity: 1;
    }
  }
}
</style>
<!--
 * @Author: mulingyuer
 * @Date: 2021-09-16 15:37:12
 * @LastEditTime: 2021-10-13 11:35:38
 * @LastEditors: mulingyuer
 * @Description: 基础数据
 * @FilePath: \saas-admin-vue\src\modules\gov\components\bid-data\BasicData.vue
 * 怎么可能会有bug！！！
-->
<template>
  <BDCard title="基础数据" class="basic-data">
    <template v-for="item in data">
      <DataItem :key="item.name" :title="item.name" :bodyContent="item.total"
        :footerLeftStatus="item.week_status" :footerLeftContent="item.week"
        :footerRightStatus="item.day_status" :footerRightContent="item.day" />
    </template>
  </BDCard>
</template>

<script>
import BDCard from "../../layout/big-data/BDCard.vue";
import DataItem from "./DataItem.vue";
export default {
  props: {
    //数据
    data: {
      type: Object,
      required: true,
    },
  },
  components: {
    BDCard,
    DataItem,
  },
};
</script>

<style lang="scss" scoped>
.basic-data {
  ::v-deep.bd-content {
    padding-bottom: 0;
  }
}
</style>
/*
 * @Author: mulingyuer
 * @Date: 2021-09-16 17:05:21
 * @LastEditTime: 2021-10-21 17:26:45
 * @LastEditors: mulingyuer
 * @Description: 商协会大数据
 * @FilePath: \saas-admin-vue\src\modules\gov\api\big-data\index.js
 * 怎么可能会有bug！！！
 */
import api from "@/base/utils/request";

//基础数据
export const basic = data => {
  return api({
    url: "/admin/gov/DataManage/baseData",
    method: "post",
    data
  })
}


//会员画像
export const userImage = data => {
  return api({
    url: "/admin/gov/data_manage/userImage",
    method: "post",
    data
  })
}


// 会员增长趋势
export const userGrowth = data => {
  return api({
    url: "/admin/gov/data_manage/userGrowth",
    method: "post",
    data
  })
}


// 信息发布
export const infoPost = data => {
  return api({
    url: "/admin/gov/data_manage/infoPost",
    method: "post",
    data
  })
}

// 协会排名
export const projectRank = data => {
  return api({
    url: "/admin/gov/DataManage/projectRank",
    method: "post",
    data
  })
}

// 企业种类
export const companyType = data => {
  return api({
    url: "/admin/gov/data_manage/companyTypes",
    method: "post",
    data
  })
}


//协会列表
export const associationList = data => {
  return api({
    url: "/admin/gov/project/all",
    method: "post",
    data
  })
}


//会费收入
export const memberRevenue = data => {
  return api({
    url: "/admin/gov/data_manage/memberFee",
    method: "post",
    data
  })
}

//协会排名年份选项数据
export const projectYears = data => {
  return api({
    url: "/admin/gov/data_manage/projectYears",
    method: "post",
    data
  })
}
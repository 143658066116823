/*
 * @Author: dongjia
 * @Date: 2021-09-29 10:31:05
 * @LastEditTime: 2021-09-29 10:33:06
 * @LastEditors: aleaner
 * @Description:政务基本信息
 * @FilePath: \gov\api\base-message\index.js
 * 怎么可能会有bug！！！
 */
import api from "@/base/utils/request";

//基础数据
export const baseMsg = data => {
  return api({
    url: "/admin/gov/index/index",
    method: "post",
    data
  })
}
<template>
  <div class="big-data-enter" v-loading="loading">
    <el-card>
      <div slot="header" class="">
        <span class="card-title flex">商协会大数据</span>
      </div>
      <div class="card-content">
        <span class="card-time">更新时间：暂无</span>
        <div class="card-btn-group">
          <el-button @click="toBigData" size="small">前往</el-button>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
    };
  },
  created() {},
  methods: {
    toBigData() {
      let routerData = this.$router.resolve({
        name: "GovBigDataIndex",
      });
      window.open(routerData.href, "_blank");
    },
  },
};
</script>

<style scoped lang="scss">
.big-data-enter {
  margin: -$space;
  padding: 4px;
  background-color: #f5f6f7;
  min-height: 100%;
  flex: 1;

  .el-card,
  ::v-deep.el-card {
    & + .el-card {
      margin-top: 24px;
    }

    .el-card__header {
      padding: 24px 32px 20px;
    }
  }

  .card-title {
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: 16px;

    &::before {
      content: "";
      background-color: $primary;
      width: 3px;
      height: 16px;
      display: inline-block;
      margin-right: 8px;
    }
  }

  .card-content {
    display: flex;
    justify-content: space-between;

    .card-time {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.65);
      line-height: 14px;
    }

    .card-btn-group {
      margin-top: 6px;
    }

    .no-bind {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.85);
      line-height: 14px;
      height: 62px;
      margin: 0 auto;

      .el-link,
      a {
        margin-left: 5px;
      }
    }
  }
}
</style>

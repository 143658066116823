<!--
 * @Author: mulingyuer
 * @Date: 2021-10-21 11:54:14
 * @LastEditTime: 2021-10-21 17:35:28
 * @LastEditors: mulingyuer
 * @Description: 会员收入
 * @FilePath: \saas-admin-vue\src\modules\gov\components\bid-data\MemberRevenue.vue
 * 怎么可能会有bug！！！
-->
<template>
  <BDCard title="会费收入">
    <template #headRight>
      <AssociationSelect v-model="form.project_id" @change="getMemberRevenue" />
    </template>
    <div class="member-revenue" v-loading="loading">
      <div class="head">
        <MyDatePicker v-model="form.time" ref="MyDatePicker" @change="getMemberRevenue" />
      </div>
      <div class="content" ref="chartContent">
      </div>
    </div>
  </BDCard>
</template>

<script>
import BDCard from "../../layout/big-data/BDCard.vue";
import AssociationSelect from "./AssociationSelect.vue";
import MyDatePicker from "./MyDatePicker.vue";
import { memberRevenue } from "../../api/big-data/index";
import MyBarChart from "../../assets/echarts/bar-chart";
export default {
  data() {
    return {
      loading: false, //加载中
      form: {
        project_id: -1, //协会id
        time: [-1, -1],
      },
      mrData: {}, //获取的数据
      //图表数据
      chartData: [],
      //x轴坐标数据
      xData: [],
    };
  },
  methods: {
    //获取会费收入
    getMemberRevenue() {
      this.loading = true;
      const postData = this.getFormData();
      memberRevenue(postData)
        .then((res) => {
          const { data } = res;
          this.mrData = data;

          this.transformData();
          this.initChart();
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    //获取提交的form数据
    getFormData() {
      const data = { ...this.form };
      const [start_time = "", end_time = ""] = data.time;
      Object.assign(data, { start_time, end_time });
      Reflect.deleteProperty(data, "time");
      return data;
    },
    //转换数据格式
    transformData() {
      this.chartData = this.mrData.map((item) => item.value);
      this.xData = this.mrData.map((item) => item.title);
    },
    //初始化图表
    initChart() {
      if (!this.$chart) {
        this.$chart = new MyBarChart({
          container: this.$refs.chartContent,
          data: this.chartData,
          xData: this.xData,
        });
      } else {
        this.setChartOption();
      }
    },
    //设置图表
    setChartOption() {
      this.$chart.setOption(this.xData, this.chartData);
    },
    //销毁实例
    destroyChart() {
      if (this.$chart) {
        this.$chart.dispose();
      }
    },
  },
  created() {
    this.$nextTick(() => {
      //获取时间默认值
      this.$refs["MyDatePicker"].updateTime();
      this.getMemberRevenue();
    });
  },
  components: {
    BDCard,
    AssociationSelect,
    MyDatePicker,
  },
};
</script>

<style lang="scss" scoped>
.member-revenue {
  height: 100%;
  display: flex;
  flex-direction: column;
  .head {
    flex-shrink: 0;
    margin-bottom: 10px;
    // ::v-deep.my-date-picker .el-date-editor {
    //   margin-top: 5px;
    // }
  }
  .content {
    min-width: 0;
    flex-grow: 1;
  }
}
</style>
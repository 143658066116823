<!--
 * @Author: dongjia
 * @Date: 2021-09-30 15:26:16
 * @LastEditTime: 2021-10-21 17:32:29
 * @LastEditors: mulingyuer
 * @Description: 协会排名
 * @FilePath: \saas-admin-vue\src\modules\gov\components\bid-data\AssociationRanking.vue
 * 怎么可能会有bug！！！
-->
<template>
  <BDCard style="margin-top: 0px" title="协会排名" class="association-ranking">
    <template #headRight>
      <el-select v-model="form.year" size="mini" style="width:100px" @change="getList">
        <el-option v-for="op in yearArr" :key="op.value" :label="op.year" :value="op.value">
        </el-option>
      </el-select>
    </template>
    <el-scrollbar class="association-list" ref="scrollBar" v-loading="loading">
      <div class="association-item" v-for="(item, index) in list" :key="index">
        <div class="item-left">
          <div class="item-rank">{{index + 1}}</div>
          <div class="item-name">{{item.name}}</div>
        </div>
        <div class="item-right">
          <div class="right-star" v-for="star in 5">
            <img class="star-icon" :style="{
              backgroundColor: star <= item.star ?'#3479EF':'#E5E5E5'
            }" src="../../assets/images/star-icon.png" />
          </div>
        </div>
      </div>
    </el-scrollbar>
  </BDCard>
</template>

<script>
import { projectRank, projectYears } from "../../api/big-data/index.js";
import BDCard from "../../layout/big-data/BDCard.vue";
export default {
  data() {
    return {
      loading: false,
      list: [],
      form: {
        year: "",
      },
      scrollHeight: 0,
      yearArr: [],
    };
  },
  methods: {
    // 获取协会排名数据
    getList() {
      this.loading = true;
      projectRank()
        .then((res) => {
          const { data } = res;
          this.list = data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    //获取年份选项数据
    getProjectYears() {
      return new Promise((resolve, reject) => {
        projectYears()
          .then((res) => {
            const { data } = res;
            this.yearArr = data;
            this.form.year = data[0].value || "";

            return resolve(res);
          })
          .catch((error) => reject(error));
      });
    },
  },
  async created() {
    try {
      this.loading = true;
      await this.getProjectYears();
      this.getList();
    } catch (error) {
      console.error(error);
      this.loading = false;
    }
  },
  components: {
    BDCard,
  },
};
</script>

<style lang="scss" scoped>
.association-ranking {
  ::v-deep.bd-content {
    height: 0;
    min-height: 200px;
  }
  .association-list {
    height: 100%;
    .association-item + .association-item {
      margin-top: 16px;
    }
    .association-item {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .item-left {
        display: flex;
        align-items: center;
        .item-rank {
          flex-shrink: 0;
          font-size: 14px;
          font-weight: bold;
          color: rgba(0, 0, 0, 0.85);
          line-height: 20px;
        }
        .item-name {
          margin-left: 15px;
          font-size: 14px;
          font-weight: bold;
          color: rgba(0, 0, 0, 0.85);
          line-height: 20px;
        }
      }
      .item-right {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        .right-star + .right-star {
          margin-left: 8px;
        }
        .right-star {
          .star-icon {
            width: 14px;
            height: 14px;
          }
        }
      }
    }
    ::v-deep.el-scrollbar__wrap {
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
}
</style>

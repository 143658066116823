var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"data-item"},[_c('div',{staticClass:"head"},[_c('h2',{staticClass:"title"},[_vm._v(_vm._s(_vm._f("placeholder")(_vm.title)))]),(!_vm.hideHelp)?_c('Help',{attrs:{"content":"提示的文本"}}):_vm._e()],1),_c('div',{staticClass:"body"},[_c('strong',[_vm._v(_vm._s(_vm._f("placeholder")(_vm._f("toPrice")(_vm.bodyContent))))])]),_c('div',{staticClass:"footer"},[_c('div',{staticClass:"footer-left"},[_c('span',{staticClass:"footer-title"},[_vm._v(_vm._s(_vm._f("placeholder")(_vm.footerLeftTitle)))]),(_vm.footerLeftStatus)?_c('i',{class:{
          'el-icon-caret-top':_vm.footerLeftStatus === 1,
          'success':_vm.footerLeftStatus === 1,
          'el-icon-caret-bottom':_vm.footerLeftStatus === 2,
          'error':_vm.footerLeftStatus === 2}}):_vm._e(),(_vm.footerLeftStatus)?_c('span',{staticClass:"footer-content"},[_vm._v(_vm._s(_vm._f("placeholder")(_vm.footerLeftContent))+"%")]):_vm._e(),(!_vm.footerLeftStatus)?_c('span',{staticClass:"footer-content",staticStyle:{"margin-left":"10px"}},[_vm._v("--")]):_vm._e()]),_c('div',{staticClass:"footer-right"},[_c('span',{staticClass:"footer-title"},[_vm._v(_vm._s(_vm._f("placeholder")(_vm.footerRightTitle)))]),(_vm.footerRightStatus)?_c('i',{class:{
          'el-icon-caret-top':_vm.footerRightStatus === 1,
          'success':_vm.footerRightStatus === 1,
          'el-icon-caret-bottom':_vm.footerRightStatus === 2,
          'error':_vm.footerRightStatus === 2}}):_vm._e(),(_vm.footerRightStatus)?_c('span',{staticClass:"footer-content"},[_vm._v(_vm._s(_vm._f("placeholder")(_vm.footerRightContent))+"%")]):_vm._e(),(!_vm.footerRightStatus)?_c('span',{staticClass:"footer-content",staticStyle:{"margin-left":"10px"}},[_vm._v("--")]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }
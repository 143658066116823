<!--
 * @Author: mulingyuer
 * @Date: 2021-09-01 17:09:52
 * @LastEditTime: 2021-09-16 16:25:35
 * @LastEditors: mulingyuer
 * @Description: 帮助提示
 * @FilePath: \saas-admin-vue\src\base\components\Popover\Help.vue
 * 怎么可能会有bug！！！
-->
<template>
  <el-tooltip class="help-prompt" :effect="effect" :content="content" :placement="placement">
    <i class="help-prompt-icon" :class="icon"></i>
  </el-tooltip>
</template>

<script>
export default {
  props: {
    //主题
    effect: {
      type: String,
      default: "dark",
    },
    //提示的文本内容
    content: {
      type: String,
      default: "提示的文本",
    },
    //显示方向
    placement: {
      type: String,
      default: "top",
    },
    //图标
    icon: {
      type: String,
      default: "el-icon-question",
    },
  },
};
</script>

<style lang="scss" scoped>
.help-prompt-icon {
  margin: 0 8px;
  cursor: pointer;
}
</style>
<!--
 * @Author: mulingyuer
 * @Date: 2021-09-16 16:19:09
 * @LastEditTime: 2021-09-30 15:22:34
 * @LastEditors: aleaner
 * @Description: 数据卡片
 * @FilePath: \gov\components\bid-data\DataItem.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="data-item">
    <div class="head">
      <h2 class="title">{{title|placeholder}}</h2>
      <Help v-if="!hideHelp" content="提示的文本" />
    </div>
    <div class="body">
      <strong>{{bodyContent|toPrice|placeholder}}</strong>
    </div>
    <div class="footer">
      <div class="footer-left">
        <span class="footer-title">{{footerLeftTitle|placeholder}}</span>
        <i v-if="footerLeftStatus" :class="{
            'el-icon-caret-top':footerLeftStatus === 1,
            'success':footerLeftStatus === 1,
            'el-icon-caret-bottom':footerLeftStatus === 2,
            'error':footerLeftStatus === 2}"></i>
        <span class="footer-content" v-if="footerLeftStatus">{{footerLeftContent|placeholder}}%</span>
        <span v-if="!footerLeftStatus" class="footer-content" style="margin-left: 10px">--</span>
      </div>
      <div class="footer-right">
        <span class="footer-title">{{footerRightTitle|placeholder}}</span>
        <i v-if="footerRightStatus" :class="{
            'el-icon-caret-top':footerRightStatus === 1,
            'success':footerRightStatus === 1,
            'el-icon-caret-bottom':footerRightStatus === 2,
            'error':footerRightStatus === 2}"></i>
        <span v-if="footerRightStatus" class="footer-content">{{footerRightContent|placeholder}}%</span>
        <span v-if="!footerRightStatus" class="footer-content" style="margin-left: 10px">--</span>
      </div>
    </div>
  </div>
</template>

<script>
import Help from "@/base/components/Popover/Help.vue";
export default {
  props: {
    title: {
      type: String,
      default: "标题",
    },
    //是否显示help
    hideHelp: {
      type: Boolean,
      default: true,
    },
    //help的内容
    helpContent: {
      type: String,
      default: "",
    },
    //body的值
    bodyContent: {
      type: [Number, String],
      default: "",
    },
    //底部左标题
    footerLeftTitle: {
      type: String,
      default: "周同比",
    },
    //底部左状态
    footerLeftStatus: {
      type: Number,
      default: 0,
    },
    //底部左内容
    footerLeftContent: {
      type: [Number, String],
      default: "",
    },
    //底部右标题
    footerRightTitle: {
      type: String,
      default: "日环比",
    },
    //底部右状态
    footerRightStatus: {
      type: Number,
      default: 0,
    },
    //底部右内容
    footerRightContent: {
      type: [Number, String],
      default: "",
    },
  },
  components: {
    Help,
  },
};
</script>

<style lang="scss" scoped>
.data-item {
  display: inline-block;
  width: 234px;
  height: 134px;
  padding: 17px 20px 20px;
  border: 1px solid #e9e9e9;
  vertical-align: top;
  margin-right: 20px;
  margin-bottom: 20px;
  > .head {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 20px;
    color: #888;
    margin-bottom: 8px;
    .title {
      flex-grow: 1;
      min-height: 0;
      font-size: inherit;
      font-weight: normal;
      @include nowrap();
    }
  }
  > .body {
    margin-bottom: 26px;
    strong {
      display: block;
      font-size: 27px;
      color: #3a3a3a;
      line-height: 27px;
      @include nowrap();
    }
  }
  > .footer {
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 13px;
    color: #888;
    .footer-left,
    .footer-right {
      min-width: 0;
      flex-grow: 1;
      i {
        margin-right: 5px;
        margin-left: 5px;
        font-size: 16px;
        &.success {
          color: $success;
        }
        &.error {
          color: $danger;
        }
      }
      .footer-content {
        color: #333;
      }
    }
  }
}
</style>

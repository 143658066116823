<!--
 * @Author: mulingyuer
 * @Date: 2021-09-13 16:20:06
 * @LastEditTime: 2021-09-29 11:42:23
 * @LastEditors: aleaner
 * @Description: 组织信息
 * @FilePath: \gov\components\base-message\BaseInformation.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="modular organize-information">
    <h2 class="modular-title">基本信息</h2>
    <div class="modular-content">
      <div class="item" v-for="item in itemArr" :key="item.key">
        <div class="label">{{item.label}}：</div>
        <!-- logo -->
        <div v-if="item.key==='logo'" class="value">
          <CertView :src="data[item.key]" width="100px" height="100px" />
        </div>
        <!-- 其他 -->
        <div v-else class="value">
          {{item.prefix?item.prefix:""}}{{data[item.key]|placeholder}}{{item.suffix?item.suffix:""}}
          <el-button v-if="item.click" type="text" class="btn" @click="onClick(item.click)">
            {{item.click.name}}
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CertView from "@/base/components/Preview/CertView.vue";
import { copyText } from "@/base/utils/tool.js";
export default {
  props: {
    //数据
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      itemArr: [
        { label: "平台LOGO", key: "logo" },
        { label: "平台名称", key: "name" },
        {
          label: "平台码",
          key: "code",
          click: { type: "copy_code", name: "复制" },
        },
        {
          label: "平台简介",
          key: "description",
        },
      ],
    };
  },
  methods: {
    //通用点击事件
    onClick({ type }) {
      switch (type) {
        case "copy_code": //复制平台码
          this.copyCode();
          break;
        default:
          this.$message.info("开发中...");
      }
    },
    // 跳转新标签页
    JumpNewPage(data) {
      let routerData = this.$router.resolve(data);
      window.open(routerData.href, "_blank");
    },

    copyCode() {
      copyText(this.data.code)
        .then((res) => {
          this.$message.success("复制成功");
        })
        .catch((err) => {});
    },
  },
  components: {
    CertView,
  },
};
</script>

<style lang="scss" scoped>
.organize-information {
  .item {
    display: flex;
    font-size: 14px;
    line-height: 22px;
    & + .item {
      margin-top: 20px;
    }
    .label {
      width: 80px;
      flex-shrink: 0;
      color: #303133;
      text-align: right;
      white-space: nowrap;
    }
    .value {
      width: 0;
      flex-grow: 1;
      color: #000;

      .btn {
        margin-left: 15px;
        padding: 0;
      }
    }
  }
}
</style>